<template>
    <div class="body-container">
        <BannerSection title="Préstamos sin buró: ¡Obtén financiación <br class='d-none d-lg-block'/>con tu auto como garantía!"
                       subtitle="Rápido, seguro y sin dejar de usar tu auto" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Cómo funcionan los préstamos sin buró?
                        </h3>
                        
                    </span>
                    <ol>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Información del vehículo
                            </h4>
                            <span>- <b>Selecciona la marca, modelo, año y kilometraje de tu auto:</b> comienza tu
                                solicitud proporcionando detalles esenciales de tu vehículo. Esta información nos
                                ayudará a determinar la oferta adecuada para ti.</span>
                        </li>

                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Oferta inmediata
                            </h4>
                            <span>- <b>Recibe una oferta en tiempo real basada en los detalles de tu vehículo: </b>
                                partiendo de la información que proporcionas, te haremos una oferta de préstamo
                                personalizada y justa, asegurándote obtener el mejor trato posible.</span>
                        </li>

                        <br>

                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Documentación y verificación
                            </h4>
                            <span>- <b>Envía tu documentación y espera la revisión rápida: </b> sube los documentos
                                necesarios a través de nuestra plataforma segura o envíalos por Whatsapp. Nosotros
                                revisaremos rápidamente tu información para proceder al siguiente paso.</span>
                        </li>
                        <br>

                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Recibe tu dinero
                            </h4>
                            <span>- <b>En menos de 24 horas el dinero estará en tu cuenta:</b> una vez aprobada
                                tu solicitud y verificada tu documentación, el dinero será depositado directamente en tu
                                cuenta bancaria, permitiéndote acceso inmediato a los fondos.</span>
                        </li>


                    </ol>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Beneficios de elegirnos
                        </h3>
                       
                    </span>
                    <ul>
                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Aceptamos a personas incluso en Buró de Crédito
                            </h3>
                            <span>Entendemos que todos pueden enfrentar dificultades financieras. Por eso, ofrecemos
                                soluciones inclusivas sin importar tu historial crediticio. Aquí, tu auto es tu
                                crédito.</span>
                        </li>

                        <br>
                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Proceso 100% en línea, desde la solicitud hasta la aprobación
                            </h3>
                            <span>Disfruta de la comodidad de gestionar tu préstamo desde cualquier lugar y en cualquier
                                momento. Nuestro proceso digital simplifica cada paso, asegurando que todo sea rápido y
                                sin complicaciones.</span>
                        </li>
                        <br>

                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                No requiere dejar tu auto como en el empeño tradicional
                            </h3>
                            <span>A diferencia de los empeños convencionales, con nosotros puedes seguir conduciendo tu
                                auto mientras está en garantía por el préstamo. Tu movilidad y libertad no se verán
                                comprometidas.</span>
                        </li>
                        <br>

                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Flexibilidad en los pagos
                            </h3>
                            <span>Pagos anticipados sin penalización, diferentes formas de pago, y la posibilidad de
                                elegir la fecha de pago que mejor te convenga.</span>
                        </li>

                    </ul>

                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Quieres saber más? Respondemos tus dudas </h3>
                        
                    </span>
                    <div>
                        <h4 style="font-size: 20px; font-style: italic;">-¿Qué significa 'préstamos sin buró'?</h4>
                        <p style="font-size: 17px;">Un préstamo sin buró se refiere a que no consideramos tu historial
                            en el Buró de Crédito como un factor determinante para la aprobación de tu préstamo. Nos
                            enfocamos en la garantía de tu vehículo.</p>
                    </div>
                    <br>
                    <div>
                        <h4 style="font-size: 20px; font-style: italic;">- ¿Cuáles son los requisitos para solicitar un
                            préstamo sin buró?</h4>
                        <p style="font-size: 17px;">Los principales requisitos incluyen ser el propietario de un
                            vehículo que esté en buenas condiciones, tener la documentación en regla del auto y
                            proporcionar tu identificación oficial. </p>
                    </div>
                    <br>
                    <div>
                        <h4 style="font-size: 20px; font-style: italic;">- ¿Es necesario realizar un cambio de
                            propietario para obtener el préstamo?</h4>
                        <p style="font-size: 17px;">No, en Ibancar no cambiamos la titularidad de tu vehículo al
                            entregar un préstamo. Tu auto sirve como garantía, pero seguirás siendo el propietario y
                            podrás usarlo mientras dure el término del préstamo. </p>
                    </div>
                    <br>
                    <div>
                        <h4 style="font-size: 20px; font-style: italic;">- ¿Cómo aseguran la confidencialidad de mi
                            información?</h4>
                        <p style="font-size: 17px;">Tomamos la seguridad de tus datos muy en serio. Utilizamos
                            tecnología de cifrado avanzada y cumplimos con todas las normativas legales para proteger tu
                            información personal y financiera. </p>
                    </div>
                    <br>
                    <p>No dejes pasar más tiempo, tu préstamo te espera.
                    </p>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosSinBuro',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos sin Buró| Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Accede a préstamos con mal buró de crédito usando tu coche como garantía. Aprobación rápida y sin complicaciones, ¡obtén el dinero que necesitas hoy mismo!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos sin buro'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>